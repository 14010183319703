<!--  -->
<template>
    <div>
        <div class="title-wrap">
            <h3>陪伴式就业
                <span>1-3-6计划</span>
            </h3>
            <h3>技术与能力并重职业才能发展更好</h3>

        </div>
        <div class='mobile-job' style="padding-top:5rem">
            <!-- 就业后第1月 -->
            <div class="first">
                <div class="text1">
                    <h3>对项目业务需求理解不明确，无从入手</h3>
                    <h4>理清需求，并给与解决问题的思路</h4>
                </div>
                <div class="text2">
                    <h3>项目使用非主流的框架及技术</h3>
                    <h4>提供自主学习资源，鼓励自主钻研
                    </h4>
                </div>
                <div class="bg">
                    <span>就业后第1月</span>
                </div>
                <div class="text3">
                    <h3>各种报错问题</h3>
                    <h4>指出关键点／远程指导／帮助解决</h4>
                </div>
                <div class="text4">
                    <h3>工作挑战和心理压力</h3>
                    <h4>分析、支持、陪伴鼓励</h4>
                </div>
            </div>
            <div style="border-top:dashed 1px #fff;padding: 2rem 0 3rem;"></div>
            <!-- 就业后第2月 -->
            <div class="first">
                <div class="text1">
                    <h3>针对不同类型项目解决方案的指导</h3>
                    <h4>总结、分享、交流工作体会</h4>
                </div>
                <div class="text2">
                    <h3>技术能力的再提高</h3>
                    <h4>提供资源、谷友汇支持
                    </h4>
                </div>
                <div class="bg">
                    <span>就业后第2月</span>
                </div>
                <div class="text3">
                    <h3>协作与人际关系
                    </h3>
                    <h4>分析、分享、支持、陪伴
                    </h4>
                </div>
                <div class="text4">
                    <h3>挑战高薪工作及心理疏导
                    </h3>
                    <h4>分析、支持、陪伴鼓励
                    </h4>
                </div>
            </div>
            <div style="border-top:dashed 1px #fff;padding: 2rem 0 3rem;"></div>
            <!-- 就业后第3月 -->
            <div class="first">
                <div class="text1">
                    <h3>薪酬和跳槽的问题
                    </h3>
                    <h4>依情况而定，稳中求进
                    </h4>
                </div>
                <div class="text2">
                    <h3>新技术的运用
                    </h3>
                    <h4>提供资源、谷友汇支持
                    </h4>
                </div>
                <div class="bg">
                    <span>就业后第3月</span>
                </div>
                <div class="text3">
                    <h3>岗位重塑及人际关系
                    </h3>
                    <h4>分析、分享、支持、陪伴
                    </h4>
                </div>
                <div class="text4">
                    <h3>工作挑战和心理释压
                    </h3>
                    <h4>分析、支持、陪伴鼓励
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
.mobile-job {
    background: #00a0ea;
    overflow: hidden;


    .title-wrap {
        margin-top: 0;
        padding: 2rem;
    }

    .first {
        height: 20rem;
        position: relative;

        .text1 {
            width: calc(50% - 2rem);
            position: absolute;
            top: -9rem;
            left: 1rem;

            h3 {
                font-size: 1.4rem;
                color: #fff;
            }

            h4 {
                font-size: 1.2rem;
                color: #fff;
            }
        }

        .text2 {
            width: calc(50% - 2rem);
            position: absolute;
            top: -9rem;
            right: 1rem;
            text-align: left;

            h3 {
                font-size: 1.4rem;
                color: #fff;
            }

            h4 {
                font-size: 1.2rem;
                color: #fff;
            }
        }

        .text3 {
            width: calc(50% - 2rem);
            position: absolute;
            bottom: 2rem;
            left: 1rem;

            h3 {
                font-size: 1.4rem;
                color: #fff;
            }

            h4 {
                font-size: 1.2rem;
                color: #fff;
            }
        }

        .text4 {
            width: calc(50% - 2rem);
            position: absolute;
            bottom: 2rem;
            right: 1rem;

            h3 {
                font-size: 1.4rem;
                color: #fff;
            }

            h4 {
                font-size: 1.2rem;
                color: #fff;
            }
        }
    }

    .bg {
        width: 15rem;
        height: 13rem;
        background: url("../../../assets/images/job.png") no-repeat;
        background-size: 100% 100%;
        margin: 6rem auto 5rem;
        text-align: center;
        line-height: 13rem;
        color: #fff;
        overflow: hidden;

        span {
            display: block;
            width: 5rem;
            line-height: 2.6rem;
            margin: 0 auto;
            margin-top: 4rem;
        }
    }
}
</style>