<!--  -->
<template>
  <div class="mobile-page">
    <MobileHeader></MobileHeader>
    <MobileSwiper></MobileSwiper>
    <MobileCourse />
    <MobileHot></MobileHot>
    <MobileStudy />
    <MobileLesson />
    <MobileTeacher />
    <MobileJob />
    <MobileFooter />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import MobileHeader from "./components/MobileHeader.vue";
import MobileSwiper from "./components/MobileSwiper.vue";
import MobileCourse from "./components/MobileCourse.vue"
import MobileHot from "./components/MobileHot.vue";
import MobileLesson from "./components/MobileLesson.vue";
import MobileTeacher from "./components/MobileTeacher.vue";
import MobileStudy from "./components/MobileStudy.vue";
import MobileFooter from "./components/MobileFooter.vue";
import MobileJob from "./components/MobileJob.vue"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    MobileHeader,
    MobileSwiper,
    MobileCourse,
    MobileHot,
    MobileLesson,
    MobileTeacher,
    MobileStudy,
    MobileFooter,
    MobileJob
  },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less">
@import "../../assets/css/common.css";
@import "../../assets/css/m-index.css";
@import "../../assets/css/m-main.css";

.title-wrap {
  margin-top: 2rem;

  h3 {
    text-align: center;
    font-size: 2rem;

    span {
      color: #f55;
      font-size: 2rem;
    }
  }

  p {
    text-align: center;
    font-size: 1.4rem;
  }

  h3:last-child {
    margin-bottom: 2rem;
  }
}
</style>