<!-- 课程体系 -->
<template>
    <div class='mobile-course'>
        <div class="title-wrap" style="margin-top:0">
            <h3>2023全新升级<span>课程体系</span></h3>
            <p style="font-size: 1.4rem; text-align: center; margin-bottom: 2rem">
                好谷就业力教研中心权威发布，满足你的学习需求
            </p>
        </div>
        <div class="course">
            <ul class="course-tabs">
                <li :class="activeIndex == index ? 'active' : ''" v-for="(courseTab, index) in courseTabs"
                    :key="courseTab.id" @click="changeTabs(index)">
                    <h3>{{ courseTab.title }}</h3>
                    <p>{{ courseTab.tips }}</p>
                </li>
            </ul>
            <div class="course-list">
                <div class="left">
                    <ul>
                        <li :class="currentIndex == index ? 'active' : ''" v-for="(courseList, index) in courseLists"
                            :key="courseList.id" @click="changeCurrent(index)">
                            {{ courseList.title }}
                        </li>
                    </ul>
                </div>
                <!-- 前端 -->
                <div v-if="activeIndex == 0">
                    <div class="right" v-if="currentIndex == 0">
                        <h3>静态网页开发</h3>
                        <h4>可掌握的核心能力</h4>
                        <p>•熟练运用HTML语法</p>
                        <p>•掌握语义化的本质</p>
                        <p>•合理使用HTML标签编写网页的结构</p>
                        <p>•通过浮动、定位、Flex控制网页布局</p>
                        <p>•通过背景、圆角、阴影、渐变等美化网页</p>
                        <p>•结合转换、过渡、动画等高级特性增强用户体验</p>
                        <p>•能够编写大型综合性网页</p>
                        <p>•基于设计稿编写网页</p>
                        <p>•借助蓝湖协同平台进行网页开发</p>
                        <p>•体验真实团队开发流程。</p>
                    </div>
                    <div class="right" v-if="currentIndex == 1">
                        <h3>动态网页开发</h3>
                        <h4>能够开发出具有交互的网页</h4>
                        <p>•掌握客户端开发技术，如视觉交互、数据处理、安全、性能</p>
                        <p>•掌握熟练操作 DOM 的能力</p>
                        <p>•熟悉 ES6+ 语法特性</p>
                        <p>•具备面向对象编程的能力</p>
                        <p>•运用 jQuery 提高开发效率，深入面向对象思想</p>
                        <p>•运用 Bootstrap 框架开发响应式网页</p>
                    </div>
                    <div class="right" v-if="currentIndex == 2">
                        <h3>全栈开发</h3>
                        <h4>实现客户端与服务端的交互</h4>
                        <p>•掌握网页数据交互的 Ajax 技术</p>
                        <p>•掌握 Node.js 中核心模块</p>
                        <p>•具备 Node.js 的编程思维</p>
                        <p>•掌握 npm 的基本使用</p>
                        <p>•根据发包流程将自己开发的库发布到 npm 平台</p>
                        <p>•了解后端数据库的基本使用</p>
                        <p>•能够使用常见的 SQL 语句操作数据库</p>
                        <p>•能够使用 MySQL 模块在项目中操作 MySQL 数据库</p>
                        <p>•掌握 Express 结合 MySQL 编写后端 API 接口</p>
                        <p>•培养出懂后端的前端程序员</p>
                        <p>•运用 Git 对项目进行版本控制</p>
                        <p>•掌握实际开发中那些必知必会的 Git 操作</p>
                        <p>
                            •基于 jQuery + Ajax + 模板引擎 + Git
                            等技术完成后台管理项目的开发
                        </p>
                    </div>
                    <div class="right" v-if="currentIndex == 3">
                        <h3>移动端APP开发</h3>
                        <h4>主流框架讲解</h4>
                        <p>•了解组件化开发的思想</p>
                        <p>•了解路由的原理</p>
                        <p>•掌握 Webpack 的基本使用</p>
                        <p>•理解数据驱动视图的思想</p>
                        <p>•掌握 Vue 的使用步骤</p>
                        <p>
                            •掌握 Vue
                            条件渲染、列表渲染、过滤器、生命周期、计算属性、侦听器等常用
                            API
                        </p>
                        <p>•掌握 ElementUI 的基本使用</p>
                        <p>•掌握 Vue 中的组件通信技术</p>
                        <p>•掌握 Vue 常用技术栈，如 vue-cli、vue-router、vuex、vue</p>
                        <p>•运用 Vue 的基本语法开发电商后台管理系统</p>
                        <p>•运用 Vue 开发人资管理平台</p>
                        <p>•运用 Vue 移动端网站</p>
                        <p>•掌握 Vant 的使用</p>
                        <p>•熟悉企业开发流程</p>
                        <p>•熟悉团队开发流程</p>
                        <p>•运用节流和防抖进行性能优化</p>
                        <p>•理解 MVVM 的原理</p>
                        <p>•熟悉 defineProperty 的使用</p>
                        <p>•了解发布订阅设计模式</p>
                        <p>•了解 Proxy 的使用</p>
                        <p>•掌握 npm 平台发包步骤</p>
                        <p>•掌握 Vue 组件封装的技巧</p>
                        <p>掌握 React 的基本语法</p>
                        <p>•掌握 JSX 的常用语法</p>
                        <p>•掌握 create-react-app 的使用</p>
                        <p>•掌握路由的使用</p>
                        <p>•掌握 React 中组件化开发的能力</p>
                        <p>•了解高阶组件的基本使用</p>
                        <p>•熟悉组件通信的常用方法。</p>
                        <p>•掌握 React 的生命周期</p>
                        <p>•掌握 Redux 的数据管理流程</p>
                        <p>•掌握 Redux 中各组成部分的作用</p>
                        <p>•掌握 React-Redux 的基本使用</p>
                        <p>•了解展示组件和容器组件的区别</p>
                        <p>•了解单向数据流的概念</p>
                        <p>•掌握 React 中 dev-tools 的使用。</p>
                        <p>•掌握 Reac 技术栈进行移动端网站的能力</p>
                        <p>•了解百度地图 SDK 的基本使用</p>
                        <p>•掌握 antd 的基本使用</p>
                        <p>•了解项目中常见业务的开发思路</p>
                        <p>•了解组件化开发思想</p>
                        <p>•了解 CSS in JS 思想</p>
                    </div>
                    <div class="right" v-if="currentIndex == 4">
                        <h3>多端应用开发</h3>
                        <h4>小程序开发和大屏可视化开发</h4>
                        <p>•了解小程序开发的一般流程</p>
                        <p>•熟悉小程基础组件的使用</p>
                        <p>•能够基于 wxml 和 wxss 实现小程序的界面布局</p>
                        <p>•基于模板的数据绑定实现数据的动态渲染</p>
                        <p>•基于 API 实现与服务端的数据通信</p>
                        <p>•掌握小程序中高级 API 的使用。</p>
                        <p>•理解 uni-app 实现跨平台开发的原理机制</p>
                        <p>•以及 uni-app 开发小程序时开发时与原生小程序的差异</p>
                        <p>•熟悉微信支付的接入流程</p>
                        <p>•利用数据缓存实现性能优化</p>
                        <p>•了解数字大屏开发流程</p>
                        <p>•运用 Echarts 完成数据可视化项目的开发</p>
                    </div>
                </div>
                <!-- java -->
                <div v-if="activeIndex == 1">
                    <div class="right" v-if="currentIndex == 0">
                        <h3>核心编程技术</h3>
                        <h4>JAVA面向对象、数据库MySQL</h4>
                        <p>
                            •课程架构分析以及Java入门介绍，了解Java基本语法以及Java语言的特点，能够简单的语法编程。
                        </p>
                        <p>
                            •学习并了解Java面向对象三大特点：继承、封装和多态，以及学习Java复杂语句：集合，多线程，异常，网络通信等。
                        </p>
                        <p>• Mysql安装和配置，以及语句的编写。</p>
                    </div>
                    <div class="right" v-if="currentIndex == 1">
                        <h3>JAVA WEB开发</h3>
                        <h4>大型客户后台管理系统实战</h4>
                        <p>
                            •学习HTML的标签、属性等知识，学习DIV+CSS的基础知识，掌握网页布局技巧，能够用DIV+CSS完成带交互的企业网站的布局。
                        </p>
                        <p>
                            •通过JavaScript基础课程和高级课程的学习，掌握时下流行的各种网页交互的原理和方法。
                        </p>
                        <p>
                            •通过对服务器tomcat的讲解，JSP页面内容，servlet，el表达式，jstl标签，过滤，监听，ajax的相关内容学习。
                        </p>
                    </div>
                    <div class="right" v-if="currentIndex == 2">
                        <h3>⼤型企业级框架</h3>
                        <h4>大型企业权限管理系统</h4>
                        <p>
                            •orcale数据库的安装，操作，内部结构，存储过程，包，索引，分区，优化。
                        </p>
                        <p>•git学习上传，下拉。Maven结构，创建项目，介绍。</p>
                        <p>
                            •spring中IOC，DI，AOP等讲解，spring中JDBC的支持。对事务的支持，springmvc整合。
                        </p>
                    </div>
                    <div class="right" v-if="currentIndex == 3">
                        <h3>微服务分布式</h3>
                        <h4>高并发微服务分布式解决方案</h4>
                        <p>•Linxu系统结构以及基本命令，部署开发环境，搭建集群。</p>
                        <p>
                            •nginx的安装、Nginx配置虚拟主机、Nginx实现反向代理、Nginx实
                            现负载均衡。
                        </p>
                        <p>
                            •Spring家族微服务、分布式系统Dubbo的应用、分布式系统讲解，Dubbo入门、负载均衡+zookeeper、dubbo+spring实战讲解
                        </p>
                        <p>
                            •集群搭建，hadoop生态讲解，hdfs讲解，java代码在hdfs上完成单词统计案例
                        </p>
                        <p>
                            •solr
                            服务器搭建、建立索引，维护索引、数据检索(全文搜索，高亮显示，精确搜索等)、配置分词器。
                        </p>
                    </div>
                    <div class="right" v-if="currentIndex == 4">
                        <h3>实战项目演练</h3>
                        <h4>JAVA互联网百强实战项目演练</h4>
                        <p>
                            •某某政府微服务治理系统、大型集团ERP管理系统、大型网高并发网约车管理系统、国际律师网管理系统、超大型编程教育系统
                        </p>
                        <p>•⼿把手带领完成分布式高并发微服务架构、双十一好谷商城。</p>
                        <p>•分级制度因材施教，指导完成同等规模项目</p>
                    </div>
                </div>
                <!-- 测试 -->
                <div v-if="activeIndex == 2">
                    <div class="right" v-if="currentIndex == 0">
                        <h3>测试基础</h3>
                        <h4>Linux和数据库满足企业需求</h4>
                        <p>•了解软件测试行业，并知道软件测试的主流技能</p>
                        <p>•掌握测试点设计方法</p>
                        <p>•掌握缺陷管理能力</p>
                        <p>•掌握针对WEB项目功能测试能力</p>
                        <p>•能够掌握Linux和数据库的常见操作，辅助测试工作</p>
                        <p>•掌握Linux操作系统按照和配置</p>
                        <p>•熟练掌握Linux常用命令</p>
                        <p>•掌握数据库增删改查操作</p>
                        <p>•掌握项目环境搭建及项目中数据库中数据的校验</p>
                    </div>
                    <div class="right" v-if="currentIndex == 1">
                        <h3>功能测试</h3>
                        <h4>Web项目实战|移动APP测试</h4>
                        <p>•能够掌握项目测试流程，分析需求，编写合格的测试用例和缺陷报告。</p>
                        <p>•熟练掌握黑盒测试用例设计方法，进行功能测试用例设计</p>
                        <p>•熟练掌握缺陷报告的编写</p>
                        <p>•熟悉测试管理工具禅道和JIRA的使用</p>
                        <p>•掌握项目测试流程</p>
                        <p>•掌握Web项目功能测试分析和用例编写</p>
                        <p>•熟悉测试计划，测试方案，测试报告的核心内容</p>
                        <p>•掌握功能测试与数据库的关系</p>
                        <p>•掌握Fiddler工具的使用</p>
                        <p>•掌握APP测试流程和用例设计方法</p>
                    </div>
                    <div class="right" v-if="currentIndex == 2">
                        <h3>Python编程</h3>
                        <h4>熟练使用Python技术</h4>
                        <p>•熟练使用Python技术，为后续相关自动化测试课程的学习打好基础。</p>
                        <p>•掌握Python基础语法，具备基础的编程能力</p>
                        <p>•建立编程思维以及面向对象程序设计思想</p>
                    </div>
                    <div class="right" v-if="currentIndex == 3">
                        <h3>接口测试</h3>
                        <h4>解决接口自动化测试中常见问题</h4>
                        <p>•能够封装接口自动化测试框架并实现HTTP或Dubbo接口测试，解决接口自动化测试中常见问题。</p>
                        <p>•掌握接口及接口测试相关概念</p>
                        <p>•掌握使用Postman进行接口测试</p>
                        <p>•熟练掌握使用pymysql数据库实现测试数据准备、校验和清理</p>
                        <p>•掌握requests库使用及脚本封装</p>
                        <p>•掌握如何实现日志收集</p>
                        <p>•掌握如何实现接口自动化测试全量字段校验</p>
                        <p>•掌握接口测试框架的设计和封装</p>
                        <p>•掌握如何测试dubbo接口</p>
                        <p>•掌握在实际的项目中如何灵活运用接口测试的相关技术</p>
                    </div>
                    <div class="right" v-if="currentIndex == 4">
                        <h3>性能测试</h3>
                        <h4>能够对项目实现性能测试</h4>
                        <p>•能够对项目实现性能测试，使用JMeter编写性能测试脚本，设计性能测试场景，并进行测试数据监控和分析。</p>
                        <p>•熟练搭建性能测试环境</p>
                        <p>•掌握性能测试基础理论</p>
                        <p>•熟练掌握JMeter工具的使用</p>
                        <p>•掌握使用JMeter进行项目性能测试实战</p>
                        <p>•掌握性能测试监控、性能分析和性能调优的思路</p>
                        <p>•掌握如何使用Locust进行接口性能测试</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            activeIndex: 0,
            currentIndex: 0,
            courseLists: [{
                id: 1,
                title: "阶段1"
            }, {
                id: 2,
                title: "阶段2"
            }, {
                id: 3,
                title: "阶段3"
            }, {
                id: 4,
                title: "阶段4"
            }, {
                id: 5,
                title: "阶段5"
            }],
            courseTabs: [{
                id: 1,
                title: "HTML5大前端",
                tips: "全栈开发+多端发布"
            }, {
                id: 2,
                title: "JavaEE",
                tips: "Java大数据"
            }, {
                id: 3,
                title: "软件测试",
                tips: "IT行业黄金职位"
            }]
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        changeCurrent(index) {
            this.currentIndex = index
        },
        changeTabs(index) {
            this.activeIndex = index
        }
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
.mobile-course {
    background: #fff;
    padding: 2rem 0;
}

.course {
    width: 100%;

    .course-tabs {
        height: 8rem;
        width: calc(100% - 2rem);
        margin-left: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
            width: 11rem;
            height: 8rem;
            background: #f7f7f7;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 1rem;

            h3 {
                font-size: 1.4rem;
            }

            p {
                font-size: 1.2rem;
            }
        }

        .active {
            background: #e9152e;

            h3 {
                color: #fff;
            }

            p {
                color: #fff;
            }
        }
    }

    .course-list {
        width: calc(100% - 2rem);
        margin-left: 1rem;
        display: flex;
        justify-content: space-between;

        .left {
            height: 40rem;

            ul {
                li {
                    width: 11rem;
                    height: 6rem;
                    background: #f7f7f7;
                    line-height: 6rem;
                    text-align: center;
                    border-radius: 1rem;
                    margin: 2rem 0;
                    font-size: 1.4rem;
                }

                .active {
                    background: #00a0ea;
                    color: #fff;
                }
            }
        }

        .right {
            height: 38rem;
            width: 23rem;
            margin-top: 2rem;
            padding: 0.5rem;
            background: #f7f7f7;
            border-radius: 1rem;
            overflow: auto;

            h3 {
                height: 3rem;
                // background: pink;
                font-size: 1.4rem;
                line-height: 3rem;
                font-weight: bold;
                text-align: center;
            }

            h4 {
                height: 2rem;
                // background: pink;
                font-size: 1.2rem;
                line-height: 2rem;
                text-align: center;
            }

            p {
                font-size: 1.2rem;
                line-height: 2rem;
            }
        }
    }
}
</style>