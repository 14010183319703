<!--  -->
<template>
    <div class=''>
        <section class="about" style="margin:0">
            <div class="container">
                <div class="section-title">
                    <h2>关于好谷</h2>
                    <div class="line1"></div>
                    <div class="line2"></div>
                    <p>让河南学子创造中国互联网未来！</p>
                </div>
                <div class="about-content">
                    <i class="fa fa-quote-left"></i>
                    <p>好谷是河南领先的信息化智能技术提供商，河南省科技型企业，公司专注于交通、医疗、电力、职业教育等领域，拥有Solver技术咨询服务管理系统、企业在线服务管理平台、交互式研发项目管理系统、大数据公共服务平台等10多项软硬件研发专利，并通过了国家知识产权管理体系认证，致力于为客户提供信息系统技术开发、大数据的技术支持与产品服务、大学生校外实习实训与创业基地建设等。
                    </p>
                    <div class="more">
                        <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"
                            title="公司简介">查看更多&nbsp;&nbsp;<i class="fa fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div>
                <img src="@/assets/images/haogu.jpg" alt="">
            </div>
        </section>
        <section class="focus">
            <div class="container">
                <div class="focus-content">
                    <h3 style="font-size：26rem">您还在犹豫吗？</h3>
                    <p style="width:90%; margin-left:5%">欢迎联系我们，我们愿意为您解答任何有关学习及就业疑难问题！</p>
                    <div class="more" style="padding-top:0">
                        <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="立即咨询"
                            target="_blank">立即咨询&nbsp;&nbsp;<i class="fa fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <div class="container">
                <h6>联系我们</h6>
                <div class="social-icon">
                    <ul>
                        <li>
                            <a href="tel:15837108733" title="电话">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="微博">
                                <i class="fa fa-weibo" aria-hidden="true"></i>
                                <div class="social-icon-img">
                                    <img src="/static/v2wap/images/qr_weibo.jpg" alt="微博">
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="微信">

                                <i class="fa fa-weixin" aria-hidden="true"></i>
                                <div class="social-icon-img">
                                    <img src="/static/v2wap/images/qr_weixin.jpg" alt="微信">
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="qq"
                                target="_blank">
                                <i class="fa fa-qq" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="邮箱">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="footer-txt">
                    <ul>
                        <li><i class="fa fa-map-marker fa-fw" aria-hidden="true"></i><span>郑州高新区新芒果春天1号楼东厅4层</span></li>
                        <li><i class="fa fa-phone fa-fw" aria-hidden="true"></i><span><a href="tel:15837108733"
                                    style="font-size:1.6rem">15837108733</a>(点击可拨打) </span></li>
                        <li><i class="fa fa-envelope-o fa-fw" aria-hidden="true"></i><span>http://www.hg-zn.com</span>
                        </li>
                    </ul>
                </div>
                <div class="footer-links">
                    <a href="http://www.hg-zn.com" title="好谷就业力" target="_blank">好谷就业力</a>
                </div>
                <div class="footer-copyright">
                    <span>Copyright © 2022. 郑州好谷就业力 版权所有</span>
                    <a href="https://beian.miit.gov.cn/" rel="nofollow" target="_blank">豫ICP备18009515号-1
                    </a>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
footer,
.focus,
.about {
    width: 100%;
    min-width: 100%;
}

.about .about-content p {
    text-indent: 2em;
    text-align: justify;
    padding: 0 10px;
    font-size: 1.4rem;
    width: calc(100% - 20px);
}

.about .section-title {
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.container {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
}
</style>