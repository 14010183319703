<!--  -->
<template>
  <div class="mobile-hot">
    <div class="title-wrap">
      <h3>IT开发</h3>
      <h3>必须需要了解的<span>热门问题</span></h3>
    </div>
    <div class="move-news">
      <ul :class="flag ? 'trans' : ''" ref="list">
        <li v-for="newList in newLists" :key="newList.id">
          <span>{{ newList.title }}</span>
          <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">点击咨询</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      timer: null, //定时器
      flag: false,
      newLists: [
        {
          id: 1,
          title: "学完之后，包就业吗",
        },
        {
          id: 2,
          title: "Java可以自学吗?应该怎么自学?",
        },
        {
          id: 3,
          title: "英语不好，能学好前端吗？",
        },
        {
          id: 4,
          title: "学历不高，能学会前端吗?",
        },
        {
          id: 5,
          title: "没有接触过电脑，能学前端吗?",
        },
        {
          id: 6,
          title: "可以申请免费听web前端课吗?",
        },
        {
          id: 7,
          title: "学习前端有没有好的方法推荐下?",
        },
        {
          id: 8,
          title: "前端能开发游戏吗?",
        },
        {
          id: 9,
          title: "有什么基础才能学Vue？",
        },
        {
          id: 10,
          title: "Web前端多少岁不能做了？",
        },
        {
          id: 11,
          title: "学习前端对专业有要求吗?",
        },
        {
          id: 12,
          title: "学前端在二三线城市好找工作吗?",
        },
        {
          id: 13,
          title: "浏览器渲染页面流程的4个步骤",
        },
        {
          id: 14,
          title: "最新编程语言排行榜来啦",
        },
        {
          id: 15,
          title: "疫情期间如何保证学员学习效果？",
        },
        {
          id: 16,
          title: "JavaScript中Date()函数怎么用？",
        },
        {
          id: 17,
          title: "哪些浏览器支持HTML5？",
        },
        {
          id: 18,
          title: "对网站进行网站测试需要掌握哪些知识？",
        },
        {
          id: 19,
          title: "网站页面如何配色设计？",
        },
        {
          id: 20,
          title: "JavaScript是什么？可以做什么？",
        },
      ],
    };
  },
  //方法集合
  methods: {
    scrollNews() {
      //   console.log(this.$refs.list);
      let oUl = this.$refs.list; //获取dom元素
      oUl.style.top = "-4.4rem";
      this.flag = !this.flag;
      setTimeout(() => {
        this.newLists.push(this.newLists[0]); //将数组的第一个放在数组的末尾
        this.newLists.shift(); //将数组的第一个删除
        oUl.style.top = "0";
        this.flag = !this.flag;
      }, 500);
    },
  },
  mounted() {
    this.timer = setInterval(this.scrollNews, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  }, //生命周期 - 销毁完成
};
</script>
<style lang="less">
.move-news {
  height: 44rem;
  width: calc(100% - 2rem);
  margin: 1rem;
  overflow: hidden;
  position: relative;

  ul {
    width: 100%;
    height: 88rem;
    position: absolute;
    top: 0;
    left: 0;

    li {
      line-height: 4.4rem;
      font-size: 1.4rem;
      border-bottom: 1px dashed #ccc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4.4rem;

      a {
        display: block;
        width: 8rem;
        height: 2.4rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        text-align: center;
        line-height: 2.4rem;
        color: #f55;
        font-size: 1.2rem;
      }
    }
  }

  .trans {
    transition: all 0.5s linear;
  }
}
</style>