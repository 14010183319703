<!--  -->
<template>
  <div class="mobile-study">
    <div class="title-wrap">
      <h3>14天课程免费试听</h3>
      <p>好谷本期试听班报名占座截止时间：</p>
    </div>
    <div class="time">
      <span>0</span>
      <span>5</span>
      月
      <span>2</span>
      <span>0</span>
      日
    </div>
    <p style="text-align: center; color: #f55">
      学IT热门技术，大厂项目提前上手，跑赢职场！
    </p>
    <div class="input-text">
      <input type="text" required placeholder="请输入您的姓名" v-model="username" />
    </div>
    <div class="input-text">
      <input type="tel" required placeholder="请输入您的电话号码" v-model="phone" />
    </div>
    <a href="https://tb.53kf.com/code/worker/423e2e57b63230cfcf173b4d662ff8e91/11199421" @click.prevent="showMaskFn"
      class="btn">抢占学费优惠名额 >></a>
    <div class="mask" v-if="showMask">
      <div class="mask-alert">
        <div class="mask-close" @click="closeMaskFn">提示<span>X</span></div>
        <div class="btn" @click="closeMaskFn">恭喜您报名成功</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      username: "",
      phone: "",
      showMask: false
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {

  },
  //方法集合
  methods: {
    showMaskFn() {
      if (!this.username) {
        alert("请输入用户名")
      } else if (this.username && /^1[35689][0-9]{9}$/.test(this.phone)) {
        this.showMask = true
      } else if (!/^1[35689][0-9]{9}$/.test(this.phone)) {
        alert("手机号格式不正确")
      }
    },
    closeMaskFn() {
      this.showMask = false
      this.phone = ""
      this.username = ""
    }
  },
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less">
.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;


  .mask-alert {
    width: 80%;
    height: 15rem;
    background: #fff;
    border-radius: 1rem;
    position: absolute;
    top: 50%;
    margin-top: -5rem;
    margin-left: 10%;

    .mask-close {
      position: relative;
      height: 4.4rem;
      text-align: center;
      line-height: 4.4rem;
      font-size: 1.4rem;
      font-weight: bold;

      span {
        position: absolute;
        right: 0;
        width: 4.4rem;
        height: 4.4rem;
        text-align: center;
        line-height: 4.4rem;
        border-radius: 50%;

      }
    }

  }
}

.mobile-study {
  background: #efefef;
  padding: 2rem 0;

  .time {
    text-align: center;
    margin: 2rem;
    font-size: 1.4rem;

    span {
      display: inline-block;
      width: 3rem;
      height: 5rem;
      text-align: center;
      line-height: 5rem;
      background: #373737;
      color: #fff;
      font-size: 2.4rem;
      font-weight: bold;
      border-radius: 0.5rem;
      margin: 0 0.2rem;
    }
  }

  .btn {
    display: block;
    width: 25rem;
    padding: 1rem 0;
    background-image: linear-gradient(90deg, #ff0058 0%, #fd4c00 100%),
      linear-gradient(#a5a5a5, #a5a5a5);
    background-blend-mode: normal, normal;
    border-radius: 2rem;
    font-family: Alibaba-PuHuiTi-R;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    margin: 2rem auto 0;
    color: #ffffff;
    text-align: center;
    animation: mscale 1s infinite;
  }

  .input-text {
    width: calc(100% - 6rem);
    margin: 2rem 3rem;

    input {
      width: 100%;
      height: 4.4rem;
      border: 1px solid #ccc;
      line-height: 4.4rem;
      text-indent: 1rem;
      font-size: 1.6rem;
    }
  }
}
</style>d