<!--  -->
<template>
  <div class="mobile-swiper">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide mobile-slide-bg1"></div>
        <div class="swiper-slide mobile-slide-bg2"></div>
        <div class="swiper-slide mobile-slide-bg3"></div>
      </div>
    </div>
    <ul class="tips">
      <li>
        <a href="https://tb.53kf.com/code/worker/423e2e57b63230cfcf173b4d662ff8e91/11199421">免费获取热门课程</a>
      </li>
      <li>
        <a href="https://tb.53kf.com/code/worker/423e2e57b63230cfcf173b4d662ff8e91/11199421">什么样的人适合学习</a>
      </li>
      <li>
        <a href="https://tb.53kf.com/code/worker/423e2e57b63230cfcf173b4d662ff8e91/11199421">学完之后薪资水平</a>
      </li>
    </ul>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(".swiper-container", {
        loop: true,
        speed: 600,
        grabCursor: true,
        parallax: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      });
    });
  },
};
</script>
<style lang="less" scoped>
.mobile-swiper {
  margin-top: 4.4rem;
  width: 100%;
  position: relative;

  .swiper-slide {
    min-width: 100%;
  }

  .mobile-slide-bg1 {
    width: 100%;
    background: url("../../../assets/images/banner.jpg") no-repeat;
    height: 50rem;
    background-size: 100%;
  }

  .mobile-slide-bg2 {
    width: 100%;
    background: url("../../../assets/images/banner2.jpg") no-repeat;
    height: 50rem;
    background-size: 100%;
  }

  .mobile-slide-bg3 {
    width: 100%;
    background: url("../../../assets/images/banner3.jpg") no-repeat;
    height: 50rem;
    background-size: 100%;
  }

  .tips {
    width: 55%;
    position: absolute;
    bottom: 1rem;
    margin-left: 20%;
    z-index: 99;

    li {
      width: 100%;
      height: 4.4rem;
      background: #f55;
      border-radius: 2.2rem;

      line-height: 4.4rem;
      text-align: center;
      margin-bottom: 1rem;
      animation: mscale 1s infinite;

      a {
        color: #fff;
        text-decoration: underline;
        font-size: 1.4rem;
      }
    }

    li:first-child {
      background: #2405a0;
    }

    li:nth-child(2) {
      background: rgb(212, 50, 50);
    }
  }
}

@keyframes mscale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.94);
  }

  100% {
    transform: scale(1);
  }
}
</style>