<!--  -->
<template>
  <div class="mobile-lesson">
    <div class="title-wrap">
      <h3>学习资料<span>免费领</span></h3>
      <h3>更新紧贴企业需求的精品课程</h3>
    </div>
    <ul class="mobile-lesson-list">
      <li v-for="lessonListData in lessonListDatas" :key="lessonListData.id">
        <img :src="lessonListData.pic" alt="" />
        <h3>{{ lessonListData.title }}</h3>
        <p>
          <span>
            <i class="fa fa-eye" aria-hidden="true"></i>{{ lessonListData.read_num }}
          </span>
          <span>
            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>{{ lessonListData.like_num }}
          </span>
        </p>
        <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" class="btn">立即领取</a>
      </li>
    </ul>
    <ul class="item">
      <li>
        <h3>前端课程大纲</h3>
        <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">免费领取</a>
      </li>
      <li>
        <h3>JAVA课程大纲</h3>
        <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">免费领取</a>
      </li>
      <li>
        <h3>测试课程大纲</h3>
        <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">免费领取</a>
      </li>
    </ul>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      lessonListDatas: [
        {
          id: 1,
          pic: require("@/assets/images/lesson01.jpg"),
          title: "HTML+CSS零基础转行入门实战",
          create_time: "2022-04-01",
          read_num: 6523,
          like_num: 2359,
        },
        {
          id: 2,
          pic: require("@/assets/images/lesson02.jpg"),
          title: "javaScript零基础必备教程",
          create_time: "2022-04-03",
          read_num: 7813,
          like_num: 6542,
        },
        {
          id: 3,
          pic: require("@/assets/images/lesson03.jpg"),
          title: "javaScript动画入门实战课程",
          create_time: "2022-04-05",
          read_num: 5003,
          like_num: 3999,
        },
        {
          id: 4,
          pic: require("@/assets/images/lesson04.jpg"),
          title: "ES6教程，涵盖ES6-ES11",
          create_time: "2022-04-05",
          read_num: 1300,
          like_num: 989,
        },
        {
          id: 5,
          pic: require("@/assets/images/lesson05.jpg"),
          title: "全新 Vue.js 3.0 核心技术合集",
          create_time: "2022-04-07",
          read_num: 1970,
          like_num: 1234,
        },
        {
          id: 6,
          pic: require("@/assets/images/lesson06.jpg"),
          title: "Redis视频教程-Redis深入浅出",
          create_time: "2022-04-08",
          read_num: 1100,
          like_num: 999,
        },
        {
          id: 7,
          pic: require("@/assets/images/lesson07.jpg"),
          title: "一周轻松掌握MySQL基础知识",
          create_time: "2022-04-11",
          read_num: 1280,
          like_num: 909,
        },
        {
          id: 8,
          pic: require("@/assets/images/lesson08.jpg"),
          title: "零基础前端React实战教程",
          create_time: "2022-04-15",
          read_num: 2000,
          like_num: 1239,
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less">
.mobile-lesson {
  .mobile-lesson-list {
    width: calc(100% - 2rem);
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;

    li:nth-child(2n + 1) {
      width: calc(50% - 0.5rem);
      margin-right: 0.5rem;
    }

    li:nth-child(2n) {
      width: calc(50% - 0.5rem);
      margin-left: 0.5rem;
    }

    h3 {
      height: 3rem;
      line-height: 3rem;
      white-space: normal;
      font-size: 1.4rem;
      font-weight: normal;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    p {
      span {
        font-size: 1.2rem;
        color: #666;

        i {
          margin-right: 0.5rem;
        }
      }
    }

    .btn {
      width: 15rem;
      height: 3rem;
      background: #216ef4;
      text-align: center;
      line-height: 3rem;
      font-size: 1.2rem;
      color: #fff;
      border-radius: 1.5rem;
      margin: 1rem auto;
      display: block;
      box-shadow: 0 0.19rem 0.35rem rgb(33 110 244 / 21%);
      animation: mscale 1s infinite;
    }
  }

  .item {
    width: calc(100% - 2rem);
    margin: 1rem;
    display: flex;
    justify-content: space-between;

    li {
      width: 30%;
      height: 10rem;
      background: #f55;

      h3 {
        font-size: 1.4rem;
        text-align: center;
        color: #fff;
        line-height: 4rem;
        margin-top: 1rem;
      }

      a {
        display: block;
        width: 8rem;
        height: 2rem;
        line-height: 2rem;
        background-color: #ffffff;
        border-radius: 1rem;
        text-align: center;
        font-size: 1.2rem;
        margin: 0.4rem auto 0 auto;
        color: blue;
        animation: mscale 1s infinite;
      }
    }
  }
}

@keyframes mscale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.94);
  }

  100% {
    transform: scale(1);
  }
}
</style>